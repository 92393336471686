import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentsListEventsService } from './documents-list-events.service';
import { DataTableItem } from 'src/app/shared/components/data-table/client-side-data-table/client-side-data-table.component';
import { dataTableEvents } from 'src/app/core/constants/data-table-events';
import { ThreeDotsMenuPipe } from 'src/app/shared/pipes/three-dots-menu.pipe';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EventsServiceAction, EventsServiceData } from 'src/app/core/services/base-event-service';
import { DateShortPipe } from 'src/app/shared/pipes/date-short.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DOC_STATUS } from 'src/app/core/models/status.enum';
import { DocStatusPipe } from 'src/app/shared/pipes/doc-status.pipe';
import { DocumentsApiService } from '../../services/documents-api.service';
import { Store } from '@ngrx/store';
import { fromDocuments } from '../../store/selectors';
import {
  DocumentOperation,
  GetDocumentDto,
  GetDocumentDtoExtended,
} from '../../models/documents.model';
import { PartiesPipe } from 'src/app/shared/pipes/parties.pipe';
import { DocTypePipe } from 'src/app/shared/pipes/doc-type.pipe';
import { MyPositionPipe } from 'src/app/shared/pipes/my-position.pipe';
import { DocumentsActions } from '../../store/actions';
import { DocEditModalComponent } from '../modals/doc-edit-modal/doc-edit-modal.component';
import { constants } from 'src/app/core/constants/constants';
import { CounterpartiesActions } from 'src/app/modules/counterparties/store/actions';
import { selectCounterparties } from 'src/app/modules/counterparties/store/selectors';
import { DocSendReportModalComponent } from '../modals/doc-send-report-modal/doc-send-report-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrl: './documents-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsListComponent implements OnInit {
  public data: GetDocumentDto[] | undefined;
  public documents: GetDocumentDtoExtended[] | null = [];
  public columns!: DataTables.ColumnSettings[];
  public sort!: (string | number)[][];
  public dataTableMenuItems!: DataTableItem[];
  public refreshDataTable: BehaviorSubject<any> = new BehaviorSubject(null);
  public STATUS = DOC_STATUS;
  public constants = constants;

  constructor(
    private readonly store$: Store,
    private router: Router,
    public documentsListEventsService: DocumentsListEventsService,
    public threeDotsMenuPipe: ThreeDotsMenuPipe,
    public dateShortPipe: DateShortPipe,
    private translocoService: TranslocoService,
    public bsModalService: BsModalService,
    public docStatusPipe: DocStatusPipe,
    public partiesPipe: PartiesPipe,
    public docTypePipe: DocTypePipe,
    public myPositionPipe: MyPositionPipe,
    public documentsApiService: DocumentsApiService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.documentsListEventsService.dataWasLoaded = false;
    this.initSubscriptions();
    this.fetchData();
  }

  fetchData(): void {
    this.store$.dispatch(CounterpartiesActions.getCounterparties());

    combineLatest([
      this.store$.select(fromDocuments.selectDocuments),
      this.store$.select(selectCounterparties),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([data, parties]) => {
        this.documents = data;
        this.initTable(data);
        this.refreshDataTable.next(this.columns);
        this.cdr.markForCheck();
      });
  }

  initSubscriptions(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.data) {
        this.initTable(this.data);
        this.refreshDataTable.next(this.columns);
      }
    });

    this.documentsListEventsService
      .getSubject()
      .pipe(untilDestroyed(this))
      .subscribe((data: EventsServiceData) => {
        switch (data.action) {
          case EventsServiceAction.ReviewLink: {
            this.reviewLink(data.id);
            break;
          }
          case EventsServiceAction.SendReport: {
            this.sendReport(data.id);
            break;
          }
          case EventsServiceAction.EditInDesktop: {
            this.editInDesktop(data.id);
            break;
          }
          case EventsServiceAction.QueryLink: {
            this.queryLink(data.id);
            break;
          }
          case EventsServiceAction.AddClausesLink: {
            this.addClausesLink(data.id);
            break;
          }
          case EventsServiceAction.EditProperties: {
            this.editProperties(data.id);
            break;
          }
          case EventsServiceAction.Delete: {
            this.delete(data.id);
            break;
          }
        }
      });
  }

  editInDesktop(id: string): void {
    const doc = this.data?.find((item: GetDocumentDto) => {
      return item.uniqueId === id;
    });

    if (doc) {
      window.open(`ms-word:ofe|u|${doc.sharedLink}`, '_blank');
      // ms-word:ofv|u|https://storyshaperdev.sharepoint.com/:w:/g/EcynK-zWFQZGkz0MENwq0r4B5xH8CedPmRD5mbplMTeTsw?e=JBHhBS
    }
  }

  sendReport(id: string): void {
    const document = this.data?.find((item: GetDocumentDto) => {
      return item.uniqueId === id;
    });

    if (document) {
      this.bsModalService.show(DocSendReportModalComponent, {
        initialState: {
          document,
        },
        class: 'modal-lg',
      });
    }
  }

  reviewLink(id: string): void {
    this.router.navigate([`documents/${id}`], { state: { tab: DocumentOperation.Review } });
  }

  queryLink(id: string): void {
    this.router.navigate([`documents/${id}`], { state: { tab: DocumentOperation.Query } });
  }

  addClausesLink(id: string): void {
    this.router.navigate([`documents/${id}`], { state: { tab: DocumentOperation.AddClauses } });
  }

  editProperties(uniqueId: string): void {
    this.store$.dispatch(DocumentsActions.loadDocument({ uniqueId }));

    this.bsModalService.show(DocEditModalComponent, {
      initialState: {
        uniqueId,
      },
      class: 'modal-xl',
    });
  }

  delete(uniqueId: string): void {
    this.store$.dispatch(DocumentsActions.deleteDocument({ uniqueId }));
  }

  initColumns(): void {
    const that = this;
    this.columns = Object.assign([
      {
        title: this.translocoService.translate('columns.docName'),
        data: 'name',
        render: function (data: any, type: any, full: any) {
          return `<a href="${full.directLink}" target="_blank"> ${data} </a>`;
        },
      },
      {
        title: this.translocoService.translate('columns.docType'),
        data: 'type',
        render: function (data: any, type: any, full: any) {
          return that.docTypePipe.transform(data);
        },
      },
      {
        title: this.translocoService.translate('columns.lastEdited'),
        data: 'lastModifiedAt',
        render: function (data: any, type: any, full: any) {
          const date = that.dateShortPipe.transform(data);
          return `<span title="${full.modifiedBy}"> ${date} </span>`;
        },
      },
      {
        title: this.translocoService.translate('columns.counterparties'),
        data: 'counterparties',
        render: function (data: any, type: any, full: any) {
          return `${that.partiesPipe.transform(data)}`;
        },
      },
      {
        title: this.translocoService.translate('columns.myPosition'),
        data: 'myPosition',
        render: function (data: any, type: any, full: any) {
          return `${that.myPositionPipe.transform(data)}`;
        },
      },
      {
        title: this.translocoService.translate('columns.status'),
        data: 'status',
        render: function (data: any, type: any, full: any) {
          return that.docStatusPipe.transform(data);
        },
      },
      {
        width: '50px',
        data: 'uniqueId',
        searchable: false,
        orderable: false,
        render: function (data: any, type: any, full: any) {
          return that.threeDotsMenuPipe.transform(data, that.dataTableMenuItems);
        },
      },
    ]);
  }

  initTable(data: GetDocumentDto[] | null): void {
    this.initColumns();
    this.dataTableMenuItems = [
      {
        title: this.translocoService.translate('actions.sendReport'),
        event: dataTableEvents.common.sendReport,
        icon: 'outgoing_mail',
      },
      {
        title: this.translocoService.translate('actions.editInDesktop'),
        event: dataTableEvents.common.editInDesktop,
        icon: 'quick_reference_all',
      },
      // {
      //   title: this.translocoService.translate('actions.reviewLink'),
      //   event: dataTableEvents.common.reviewLink,
      //   icon: 'quick_reference_all',
      // },
      // {
      //   title: this.translocoService.translate('actions.queryLink'),
      //   event: dataTableEvents.common.queryLink,
      //   icon: 'rate_review',
      // },
      // {
      //   title: this.translocoService.translate('actions.addClausesLink'),
      //   event: dataTableEvents.common.addClausesLink,
      //   icon: 'rebase_edit',
      // },
      {
        title: this.translocoService.translate('actions.editProperties'),
        event: dataTableEvents.common.editProperties,
        icon: 'edit_square',
      },
      {
        title: this.translocoService.translate('actions.remove'),
        event: dataTableEvents.common.delete,
        icon: 'delete',
      },
    ];
    this.sort = [[0, 'asc']];
    this.data = data || [];
  }
}
