import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DocumentsActions } from '../../store/actions';
import { fromDocuments } from '../../store/selectors';
import { combineLatest, debounceTime, filter, tap, withLatestFrom } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocUploadModalComponent } from '../modals/doc-upload-modal/doc-upload-modal.component';
import { DocumentsListEventsService } from '../documents-list/documents-list-events.service';
import { Criterion, SearchItemModel } from 'src/app/core/services/base-event-service';
import { DocSearchCriterionsFactory } from 'src/app/core/factories/doc-search-criterions.factory';
import { DocumentTypesApiService } from 'src/app/modules/doc-types/services/document-types-api.service';
import { PartiesApiService } from 'src/app/modules/counterparties/services/parties-api.service';
import { PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { DocumentStatusesApiService } from 'src/app/shared/services/document-statuses-api.service';
import { DocumentSearchModel } from '../../models/documents.model';
import { getDocumentsFilterConditionsFromModel } from 'src/app/core/utils/documents.utils';
import { getFiltersFormModel } from 'src/app/core/utils/filter.utils';
import { selectContentLanguages } from 'src/app/shared/store/selectors';
import { selectUser } from 'src/app/core/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrl: './documents.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent implements OnInit {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public criterions: Criterion[] = this.docSearchCriterionsFactory.getAllItemsAsArray();
  public conditions: SearchItemModel[] | undefined;
  public form: FormGroup | undefined;
  public docsRecieved: boolean = false;
  public contentLanguages: any[] = [];

  constructor(
    private readonly store$: Store,
    private titleService: Title,
    private bsModalService: BsModalService,
    private documentsListEventsService: DocumentsListEventsService,
    private docSearchCriterionsFactory: DocSearchCriterionsFactory,
    private documentTypesApiService: DocumentTypesApiService,
    private partiesApiService: PartiesApiService,
    private positionsApiService: PositionsApiService,
    private documentStatusesApiService: DocumentStatusesApiService,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public docTypeItems: any;
  public docStatusItems: any;
  public positionItems: any;
  public partyItems: any;

  ngOnInit() {
    this.store$
      .select(selectUser)
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe((user) => {
        this.initForm();
        this.store$
          .select(fromDocuments.selectDocuments)
          .pipe(
            untilDestroyed(this),
            filter(isPresent),
            withLatestFrom(this.store$.select(selectUser).pipe(filter(isPresent))),
          )
          .subscribe(([data, user]) => {
            this.docsRecieved = !!data;
            this.cdr.markForCheck();
            setTimeout(() => {
              this.checkAndRestoreFilters();
            });
          });
        this.titleService.setTitle('AutoLex | Documents');
        this.documentsListEventsService.setFilterCriterions(this.criterions);
        this.store$.dispatch(DocumentsActions.getDocuments());
      });
  }

  checkAndRestoreFilters() {
    const filters = this.documentsListEventsService.getFilters();

    if (filters?.length > 0) {
      const model = getFiltersFormModel(filters);
      this.form?.patchValue(model);
    } else {
      this.documentsListEventsService.onFilter([]);
    }
  }

  initForm() {
    combineLatest([
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
      this.documentTypesApiService.getDocumentTypes$(),
      this.partiesApiService.getParties$(),
      this.positionsApiService.getPositions$(),
      this.documentStatusesApiService.getDocumentStatuses$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([contentLanguages, docTypes, parties, postitions, docStatuses]) => {
        this.docTypeItems = docTypes;
        this.partyItems = parties;
        this.positionItems = postitions;
        this.docStatusItems = docStatuses;
        this.contentLanguages = contentLanguages;

        this.form = this.fb.group({
          documentName: [''],
          documentType: [null],
          counterparty: [null],
          position: [null],
          status: [null],
          languageId: [null],
        });

        this.initSubscriptions();
        this.cdr.markForCheck();
      });
  }

  initSubscriptions(): void {
    this.form?.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(50),
        tap((model: DocumentSearchModel) => {
          this.conditions = getDocumentsFilterConditionsFromModel(
            model,
            this.docSearchCriterionsFactory,
          );
        }),
      )
      .subscribe((value) => {
        this.documentsListEventsService.resetPagination();

        if (!!this.conditions) {
          this.documentsListEventsService.onFilter(this.conditions || []);
        }
      });
  }

  clearFilters(): void {
    this.form?.reset();
    this.cdr.markForCheck();
  }

  addDocument(): void {
    this.bsModalService.show(DocUploadModalComponent, {
      initialState: {},
      class: 'modal-xl',
    });
  }
}
