import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Subject, catchError, combineLatest, filter, of } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { DOC_STATUS } from 'src/app/core/models/status.enum';
import { fileToBase64 } from 'src/app/core/utils/files.utils';
import { AlertType } from 'src/app/shared/components/app-alert/app-alert.component';
import { DocumentStatusesApiService } from 'src/app/shared/services/document-statuses-api.service';
import { DocumentTypesApiService } from 'src/app/modules/doc-types/services/document-types-api.service';
import {
  PartiesApiService,
  PartyDto,
} from 'src/app/modules/counterparties/services/parties-api.service';
import { PositionDto, PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { DocumentCommonDto, DocumentPropertiesDto } from '../../../models/documents.model';
import { UserDto } from 'src/app/modules/login/services/user-api.service';
import { selectDefaultContentLanguageId, selectUser } from 'src/app/core/store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import { DocumentsActions } from '../../../store/actions';
import { fromDocuments } from '../../../store/selectors';
import { isPresent } from 'src/app/core/utils/isPresent';
import { GetDocumentTypeDto } from 'src/app/modules/doc-types/models/doc-types.model';
import { CounterpartiesActions } from 'src/app/modules/counterparties/store/actions';
import { LanguageDto } from 'src/app/shared/services/languages-api.service';
import { selectContentLanguages } from 'src/app/shared/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-doc-upload-modal',
  templateUrl: './doc-upload-modal.component.html',
  styleUrl: './doc-upload-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocUploadModalComponent implements OnInit, AfterViewInit {
  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public addDocTypeForm: FormGroup | undefined;
  public addPartyForm: FormGroup | undefined;
  public body: string | undefined;
  public title: string = this.translocoService.translate(`titles.addDocument`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public alertTypes = AlertType;
  public constants = constants;

  public files: NgxFileDropEntry[] = [];
  public wrongFileType: boolean = false;
  public wrongFileTypeErrorMessage: string = '';

  public docTypeItems: any;
  public docStatusItems: any;
  public positionItems!: PositionDto[];
  public conterPartyItems: any;
  public hiddenConterPartyItems: any;

  public creatingNewDocType: boolean = false;
  public creatingNewParty: boolean = false;

  public user$: Observable<UserDto | null> | undefined;
  public currentUser: UserDto | null | undefined;
  public isDocumentCreating$ = this.store$.select(fromDocuments.selectDocumentCreating);
  public contentLanguages: LanguageDto[] = [];

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private elementRef: ElementRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly documentTypesApiService: DocumentTypesApiService,
    private readonly documentStatusesApiService: DocumentStatusesApiService,
    private readonly positionsApiService: PositionsApiService,
    private readonly partiesApiService: PartiesApiService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.store$
      .select(fromDocuments.selectCloseCreatingDocumentModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(DocumentsActions.clearCreateDocumentCancelled());
        }
      });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    combineLatest([
      this.store$.select(selectUser),
      this.store$.select(selectDefaultContentLanguageId),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
      this.documentTypesApiService.getDocumentTypes$(),
      this.documentStatusesApiService.getDocumentStatuses$(),
      this.positionsApiService.getPositions$(),
      this.partiesApiService.getParties$(),
    ])
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe(
        ([
          user,
          defaultContentLanguageId,
          contentLanguages,
          docTypes,
          docStatuses,
          postitions,
          parties,
        ]) => {
          this.currentUser = user;
          this.contentLanguages = contentLanguages;
          this.docTypeItems = docTypes;
          this.docStatusItems = docStatuses;
          this.positionItems = postitions;
          this.conterPartyItems = parties;
          this.hiddenConterPartyItems = [];

          setTimeout(() => {
            this.form = this.fb.group({
              documentName: ['', { validators: [Validators.required] }],
              documentType: ['', { validators: [Validators.required] }],
              counterparties: ['', { validators: [Validators.required] }],
              myPosition: ['', { validators: [Validators.required] }],
              status: ['', { validators: [Validators.required] }],
              languageId: [defaultContentLanguageId, { validators: [Validators.required] }],
            });

            this.addDocTypeForm = this.fb.group({
              newDocumentType: ['', { validators: [Validators.required] }],
            });

            this.addPartyForm = this.fb.group({
              newPartyName: ['', { validators: [Validators.required] }],
            });

            this.cdr.markForCheck();
          });
        },
      );
  }

  openNewDocTypeForm() {
    this.creatingNewDocType = true;
  }

  onConfirmNewDocType() {
    this.documentTypesApiService
      .createDocumentType$(this.addDocTypeForm?.get('newDocumentType')?.value)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.addDocTypeForm?.get('newDocumentType')?.setErrors({ docTypeNameAlreadyExist: true });
          const invalidControl = this.elementRef.nativeElement.querySelector('#newDocumentType');
          invalidControl?.focus();
          return of();
        }),
      )
      .subscribe((resp: GetDocumentTypeDto) => {
        if (resp) {
          this.documentTypesApiService
            .getDocumentTypes$()
            .pipe(untilDestroyed(this))
            .subscribe((docTypeItems) => {
              this.docTypeItems = docTypeItems;
              this.cdr.detectChanges();

              setTimeout(() => {
                this.form?.get('documentType')?.patchValue(resp.id);
                this.form?.updateValueAndValidity();
                this.onCancelNewDocType();
                this.cdr.detectChanges();
              });
            });
        } else {
          this.onCancelNewDocType();
        }
      });
  }

  onCancelNewDocType() {
    this.addDocTypeForm?.reset();
    this.creatingNewDocType = false;
    this.cdr.markForCheck();
  }

  openNewPartyForm() {
    this.creatingNewParty = true;
  }

  onConfirmNewParty() {
    this.partiesApiService
      .createParty$(this.addPartyForm?.get('newPartyName')?.value)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.addPartyForm?.get('newPartyName')?.setErrors({ partyNameAlreadyExist: true });
          const invalidControl = this.elementRef.nativeElement.querySelector('#newPartyName');
          invalidControl?.focus();
          return of();
        }),
      )
      .subscribe((resp: PartyDto) => {
        if (resp) {
          this.store$.dispatch(CounterpartiesActions.getCounterparties());

          this.partiesApiService
            .getParties$()
            .pipe(untilDestroyed(this))
            .subscribe((parties) => {
              this.conterPartyItems = parties;
              this.cdr.detectChanges();

              setTimeout(() => {
                this.form?.get('counterparties')?.patchValue([resp.id]);
                this.form?.updateValueAndValidity();
                this.onCancelNewParty();
                this.creatingNewParty = false;
                this.cdr.detectChanges();
              });
            });
        } else {
          this.creatingNewParty = false;
        }
      });
  }

  onCancelNewParty() {
    this.addPartyForm?.reset();
    this.creatingNewParty = false;
    this.cdr.markForCheck();
  }

  public dropped(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      return;
    }

    const droppedFile = files[0];

    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (constants.fileUploaderAcceptedFormatsTypes.indexOf(file.type) !== -1) {
          this.wrongFileType = false;
          this.files = files;
          const fileNameWithoutExt = files[0].fileEntry.name.split('.').slice(0, -1).join('.');
          this.form?.get('documentName')?.patchValue(fileNameWithoutExt);
          this.form?.get('status')?.patchValue(DOC_STATUS.INPROGRESS);
          this.cdr.markForCheck();
        } else {
          this.wrongFileType = true;
          this.cdr.markForCheck();
        }
      });
    }
  }

  public get isFileSelected(): boolean {
    return this.files?.length > 0;
  }

  public onConfirm(): void {
    const fileEntry = this.files[0].fileEntry as FileSystemFileEntry;

    fileEntry.file((file) => {
      fileToBase64(file).then(
        (base64Content) => {
          const myPosition = this.form?.get('myPosition')?.value;
          const counterparties = this.form
            ?.get('counterparties')
            ?.value?.split(',')
            .map((id: string) => +id);

          const properties = {
            name: this.form?.get('documentName')?.value,
            type: this.form?.get('documentType')?.value,
            status: this.form?.get('status')?.value,
            userPositionId: myPosition,
            parties: counterparties,
            languageId: this.form?.get('languageId')?.value,
          } as DocumentPropertiesDto;

          const model = {
            base64Content: base64Content.split(';base64,').pop(),
            properties,
          } as DocumentCommonDto;

          this.store$.dispatch(DocumentsActions.createDocument({ model }));
        },
        (err) => {},
      );
    });
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine && this.files?.length === 0;
    this.store$.dispatch(DocumentsActions.createDocumentCancel({ pristine }));
  }
}
