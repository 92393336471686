import { ValidatorFn, FormControl } from '@angular/forms';

export function emailListValidator(control: FormControl): { [key: string]: any } | null {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (control.value === '') {
    return null; // allow empty string
  }
  const emails = control.value.split(',');
  if (emails.length > 10) {
    return { tooManyEmails: true };
  }
  for (const email of emails) {
    const trimmedEmail = email.trim();
    if (!emailRegex.test(trimmedEmail)) {
      return { invalidEmail: true };
    }
  }
  return null;
}
