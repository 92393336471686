import { GetDocumentDto, GetDocumentDtoExtended } from '../models/documents.model';

export interface DocumentsState {
  documents: GetDocumentDtoExtended[] | null;
  loadingDocuments: boolean;
  creatingDocument: boolean;
  closeCreatingDocumentModal: boolean;
  deletingDocument: boolean;
  document: GetDocumentDto | null;
  loadingDocument: boolean;
  editingDocument: boolean;
  closeEditingDocumentModal: boolean;
  sendingReport: boolean;
  closeSendingReportModal: boolean;
}

export const initialState: DocumentsState = {
  documents: null,
  loadingDocuments: false,
  creatingDocument: false,
  closeCreatingDocumentModal: false,
  deletingDocument: false,
  document: null,
  loadingDocument: false,
  editingDocument: false,
  closeEditingDocumentModal: false,
  sendingReport: false,
  closeSendingReportModal: false,
};
