import { Injectable } from '@angular/core';
import { SearchCriterionsDataType } from '../services/client-side-search.service';
import { Criterion } from '../services/base-event-service';

@Injectable({
  providedIn: 'root',
})
export class DocSearchCriterionsFactory {
  constructor() {}
  protected readonly entities = {
    [DOC_SEARCH_CRITERION.NAME]: {
      id: DOC_SEARCH_CRITERION.NAME,
      title: 'Document Name',
      data: ['name'],
      type: SearchCriterionsDataType.String,
      controlName: 'documentName',
    },
    [DOC_SEARCH_CRITERION.TYPE]: {
      id: DOC_SEARCH_CRITERION.TYPE,
      title: 'Document Type',
      data: ['type'],
      type: SearchCriterionsDataType.Integer,
      controlName: 'documentType',
    },
    [DOC_SEARCH_CRITERION.COUNTERPARTY]: {
      id: DOC_SEARCH_CRITERION.COUNTERPARTY,
      title: 'Counterparty',
      data: ['counterparties'],
      type: SearchCriterionsDataType.ArrayNumber,
      controlName: 'counterparty',
    },
    [DOC_SEARCH_CRITERION.POSITION]: {
      id: DOC_SEARCH_CRITERION.POSITION,
      title: 'My Position',
      data: ['myPosition'],
      type: SearchCriterionsDataType.Integer,
      controlName: 'position',
    },
    [DOC_SEARCH_CRITERION.STATUS]: {
      id: DOC_SEARCH_CRITERION.STATUS,
      title: 'Position',
      data: ['status'],
      type: SearchCriterionsDataType.Integer,
      controlName: 'status',
    },
    [DOC_SEARCH_CRITERION.LANGUAGE]: {
      id: DOC_SEARCH_CRITERION.LANGUAGE,
      title: 'Language',
      data: ['languageId'],
      type: SearchCriterionsDataType.Integer,
      controlName: 'languageId',
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities) as Criterion[];
  }

  getById(id: number | DOC_SEARCH_CRITERION) {
    const item = { ...this.entities[id as DOC_SEARCH_CRITERION] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}

export enum DOC_SEARCH_CRITERION {
  NAME = 1,
  TYPE = 2,
  COUNTERPARTY = 3,
  POSITION = 4,
  STATUS = 5,
  LANGUAGE = 6,
}
