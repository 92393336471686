import { createReducer, on } from '@ngrx/store';
import { DocumentsActions } from './actions';
import { initialState } from './state';

export const featureReducer = createReducer(
  initialState,
  on(DocumentsActions.setDocuments, (state, { documents }) => ({
    ...state,
    documents: documents,
    loadingDocuments: false,
  })),
  on(DocumentsActions.loadDocuments, (state, {}) => ({
    ...state,
    loadingDocuments: true,
  })),
  on(DocumentsActions.loadDocumentsFail, (state, {}) => ({
    ...state,
    loadingDocuments: false,
  })),
  on(DocumentsActions.createDocument, (state, {}) => ({
    ...state,
    creatingDocument: true,
    closeCreatingDocumentModal: false,
  })),
  on(DocumentsActions.createDocumentCancel, (state, {}) => ({
    ...state,
    creatingDocument: false,
    closeCreatingDocumentModal: false,
  })),
  on(DocumentsActions.createDocumentSuccess, (state, {}) => ({
    ...state,
    creatingDocument: false,
    closeCreatingDocumentModal: false,
  })),
  on(DocumentsActions.createDocumentFail, (state, {}) => ({
    ...state,
    creatingDocument: false,
    closeCreatingDocumentModal: false,
  })),
  on(DocumentsActions.createDocumentCancelled, (state, {}) => ({
    ...state,
    closeCreatingDocumentModal: true,
  })),
  on(DocumentsActions.closeCreatingDocumentModal, (state, {}) => ({
    ...state,
    closeCreatingDocumentModal: true,
  })),
  on(DocumentsActions.clearCreateDocumentCancelled, (state, {}) => ({
    ...state,
    closeCreatingDocumentModal: false,
  })),
  on(DocumentsActions.deleteDocument, (state, {}) => ({
    ...state,
    deletingDocument: true,
  })),
  on(DocumentsActions.deleteDocumentCancel, (state, {}) => ({
    ...state,
    deletingDocument: false,
  })),
  on(DocumentsActions.deleteDocumentSuccess, (state, {}) => ({
    ...state,
    deletingDocument: false,
  })),
  on(DocumentsActions.deleteDocumentFail, (state, {}) => ({
    ...state,
    deletingDocument: false,
  })),
  on(DocumentsActions.setDocument, (state, { document }) => ({
    ...state,
    document: document,
    loadingDocument: false,
  })),
  on(DocumentsActions.loadDocument, (state, {}) => ({
    ...state,
    loadingDocument: true,
  })),
  on(DocumentsActions.loadDocumentFail, (state, {}) => ({
    ...state,
    loadingDocument: false,
  })),
  on(DocumentsActions.editDocument, (state, {}) => ({
    ...state,
    editingDocument: true,
    closeEditingDocumentModal: false,
  })),
  on(DocumentsActions.editDocumentCancel, (state, {}) => ({
    ...state,
    editingDocument: false,
    closeEditingDocumentModal: false,
  })),
  on(DocumentsActions.editDocumentSuccess, (state, {}) => ({
    ...state,
    editingDocument: false,
    closeEditingDocumentModal: false,
  })),
  on(DocumentsActions.editDocumentFail, (state, {}) => ({
    ...state,
    editingDocument: false,
    closeEditingDocumentModal: false,
  })),
  on(DocumentsActions.editDocumentCancelled, (state, {}) => ({
    ...state,
    document: null,
    closeEditingDocumentModal: true,
  })),
  on(DocumentsActions.closeEditingDocumentModal, (state, {}) => ({
    ...state,
    document: null,
    closeEditingDocumentModal: true,
  })),
  on(DocumentsActions.clearEditDocumentCancelled, (state, {}) => ({
    ...state,
    closeEditingDocumentModal: false,
  })),
  on(DocumentsActions.sendReport, (state, {}) => ({
    ...state,
    sendingReport: true,
    closeSendingReportModal: false,
  })),
  on(DocumentsActions.sendReportCancel, (state, {}) => ({
    ...state,
    sendingReport: false,
    closeSendingReportModal: false,
  })),
  on(DocumentsActions.sendReportSuccess, (state, {}) => ({
    ...state,
    sendingReport: false,
    closeSendingReportModal: false,
  })),
  on(DocumentsActions.sendReportFail, (state, {}) => ({
    ...state,
    sendingReport: false,
    closeSendingReportModal: false,
  })),
  on(DocumentsActions.sendReportCancelled, (state, {}) => ({
    ...state,
    closeSendingReportModal: true,
  })),
  on(DocumentsActions.closeSendingReportModal, (state, {}) => ({
    ...state,
    closeSendingReportModal: true,
  })),
  on(DocumentsActions.clearSendReportCancelled, (state, {}) => ({
    ...state,
    closeSendingReportModal: false,
  })),
);
