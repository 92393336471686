import { PartyDto } from 'src/app/modules/counterparties/services/parties-api.service';

export function getPartyById(id: number, parties: PartyDto[]): PartyDto | undefined {
  return parties.find((party) => party.id === id);
}

export function fileToBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const res = reader.result || '';
      resolve(res.toString());
    };
    reader.onerror = (error) => reject(error);
  });
}
