import { DocumentSearchModel } from 'src/app/modules/documents/models/documents.model';
import { SearchItemModel } from '../services/base-event-service';
import {
  DOC_SEARCH_CRITERION,
  DocSearchCriterionsFactory,
} from '../factories/doc-search-criterions.factory';
import { FILTER_OPERATOR } from '../services/client-side-search.service';

export function getDocumentsFilterConditionsFromModel(
  model: DocumentSearchModel,
  docSearchCriterionsFactory: DocSearchCriterionsFactory,
): SearchItemModel[] {
  let conditions: SearchItemModel[] = [];

  if (!!model.documentName) {
    const criterion = docSearchCriterionsFactory.getById(DOC_SEARCH_CRITERION.NAME);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.Contains,
      type: criterion.type,
      searchValue: model.documentName,
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.documentType) {
    const criterion = docSearchCriterionsFactory.getById(DOC_SEARCH_CRITERION.TYPE);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.documentType.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.counterparty) {
    const criterion = docSearchCriterionsFactory.getById(DOC_SEARCH_CRITERION.COUNTERPARTY);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.counterparty.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.position) {
    const criterion = docSearchCriterionsFactory.getById(DOC_SEARCH_CRITERION.POSITION);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.position.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.status) {
    const criterion = docSearchCriterionsFactory.getById(DOC_SEARCH_CRITERION.STATUS);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.status.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  if (!!model.languageId) {
    const criterion = docSearchCriterionsFactory.getById(DOC_SEARCH_CRITERION.LANGUAGE);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.ContainsFromArray,
      type: criterion.type,
      searchValue: model.languageId.join(','),
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  return conditions;
}
