import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportingApiService extends ApiService {
  constructor() {
    super();
  }

  public sendReport$(report: SendReportDto): Observable<any> {
    return this.postWithResponseText('/reporting/send-report', report);
  }
}

export interface SendReportDto {
  documentId: string;
  recipients: [string];
}

export const apiReportingProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiReporting.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiReporting.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiReporting.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiReporting.scopes.write],
  },
];
