import { FormControl, ValidatorFn, Validators } from '@angular/forms';

export function markAsRequired(control: FormControl): void {
  control?.removeValidators(Validators.required);
  control?.addValidators([Validators.required]);
  control?.updateValueAndValidity();
}
export function markAsNotRequired(control: FormControl): void {
  control?.removeValidators(Validators.required);
  control?.updateValueAndValidity();
}
