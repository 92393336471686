import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, filter } from 'rxjs';
import { selectDefaultContentLanguageId, selectUser } from 'src/app/core/store/selectors';
import { AppState } from 'src/app/core/store/state';
import { isPresent } from 'src/app/core/utils/isPresent';
import { UserDto } from 'src/app/modules/login/services/user-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { selectContentLanguages } from 'src/app/shared/store/selectors';
import { GetDocumentDto } from '../../../models/documents.model';
import { AlertType } from 'src/app/shared/components/app-alert/app-alert.component';
import { emailListValidator } from 'src/app/core/validators/email-list-validator';
import { markAsNotRequired, markAsRequired } from 'src/app/core/utils/form-controls.utils';
import { DocumentsActions } from '../../../store/actions';
import { SendReportDto } from 'src/app/shared/services/reporting-api.service';
import { fromDocuments } from '../../../store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-doc-send-report-modal',
  templateUrl: './doc-send-report-modal.component.html',
  styleUrl: './doc-send-report-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocSendReportModalComponent implements OnInit, AfterViewInit {
  @Input() document: GetDocumentDto | undefined;

  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public title: string = this.translocoService.translate(`titles.sendReport`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public alertType = AlertType;
  public currentUser: UserDto | null | undefined;
  public sending: boolean = false;

  private sendToMeFormControl!: FormControl<any>;
  private sendToOthersFormControl!: FormControl<any>;
  private sendToOthersEmailsFormControl!: FormControl<any>;

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private elementRef: ElementRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterViewInit(): void {
    this.store$
      .select(fromDocuments.selectCloseSendingReportModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(DocumentsActions.clearSendReportCancelled());
        }
      });

    this.store$
      .select(fromDocuments.selectSendingReport)
      .pipe(untilDestroyed(this))
      .subscribe((sending) => {
        this.sending = sending;
      });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  initControls() {
    this.sendToMeFormControl = this.form?.get('sendToMe') as FormControl;
    this.sendToOthersFormControl = this.form?.get('sendToOthers') as FormControl;
    this.sendToOthersEmailsFormControl = this.form?.get('sendToOthersEmails') as FormControl;
  }

  initSubscription() {
    this.sendToOthersFormControl.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((value) => {
        this.onSendToOthersChange(value);
      });
  }

  initForm() {
    combineLatest([
      this.store$.select(selectUser),
      this.store$.select(selectDefaultContentLanguageId),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
    ])
      .pipe(untilDestroyed(this), filter(isPresent))
      .subscribe(([user, defaultContentLanguageId, contentLanguages]) => {
        this.currentUser = user;

        setTimeout(() => {
          this.form = this.fb.group({
            sendToMe: [true, { validators: [] }],
            sendToOthers: [false, { validators: [] }],
            sendToOthersEmails: ['', { validators: [emailListValidator] }],
          });

          this.initControls();
          this.initSubscription();
          this.cdr.markForCheck();
        });
      });
  }

  onSendToOthersChange(value: boolean): void {
    if (value === true) {
      markAsRequired(this.sendToOthersEmailsFormControl);
    } else {
      this.sendToOthersEmailsFormControl.patchValue('');
      markAsNotRequired(this.sendToOthersEmailsFormControl);
    }

    this.cdr.markForCheck();
  }

  get isSendToOthers(): boolean {
    return this.sendToOthersFormControl.value === true;
  }

  get isSendToOthersEmailsInvalid(): boolean {
    return false;
  }

  get isFormValid(): boolean {
    if (this.form?.invalid) {
      return false;
    }

    return this.sendToMeFormControl.value === true || this.sendToOthersFormControl.value === true;
  }

  public onConfirm(): void {
    let documentId = this.document?.uniqueId;
    let recipients = [];
    if (this.sendToMeFormControl.value === true) {
      recipients.push(this.currentUser?.email);
    }

    if (this.sendToOthersFormControl.value === true) {
      recipients = recipients.concat(
        this.sendToOthersEmailsFormControl.value.split(',')?.map((email: string) => email.trim()),
      );
    }

    const report = {
      documentId,
      recipients,
    } as SendReportDto;

    this.store$.dispatch(DocumentsActions.sendReport({ report }));
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine;
    this.store$.dispatch(DocumentsActions.sendReportCancel({ pristine }));
  }
}
