import { fromDocuments } from './../../../store/selectors';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
  AfterContentChecked,
  ElementRef,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable, Subject, catchError, combineLatest, filter, of } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { AlertType } from 'src/app/shared/components/app-alert/app-alert.component';
import { DocumentStatusesApiService } from 'src/app/shared/services/document-statuses-api.service';
import {
  PartiesApiService,
  PartyDto,
} from 'src/app/modules/counterparties/services/parties-api.service';
import { PositionDto, PositionsApiService } from 'src/app/shared/services/positions-api.service';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';
import { DocumentPropertiesDto, GetDocumentDto } from '../../../models/documents.model';
import { UserDto } from 'src/app/modules/login/services/user-api.service';
import { selectUser } from 'src/app/core/store/selectors';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import { DocumentsActions } from '../../../store/actions';
import { isPresent } from 'src/app/core/utils/isPresent';
import { DocumentTypesApiService } from 'src/app/modules/doc-types/services/document-types-api.service';
import { GetDocumentTypeDto } from 'src/app/modules/doc-types/models/doc-types.model';
import { CounterpartiesActions } from 'src/app/modules/counterparties/store/actions';
import { LanguageDto } from 'src/app/shared/services/languages-api.service';
import { selectContentLanguages } from 'src/app/shared/store/selectors';

@UntilDestroy()
@Component({
  selector: 'app-doc-edit-modal',
  templateUrl: './doc-edit-modal.component.html',
  styleUrl: './doc-edit-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocEditModalComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @Input() uniqueId!: string;
  public onClose: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup | undefined;
  public addDocTypeForm: FormGroup | undefined;
  public addPartyForm: FormGroup | undefined;
  public body: string | undefined;
  public title: string = this.translocoService.translate(`titles.editDocument`);
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;
  public alertTypes = AlertType;
  public constants = constants;

  public docTypeItems: any;
  public docStatusItems: any;
  public positionItems!: PositionDto[];
  public conterPartyItems: any;
  public hiddenConterPartyItems: any;

  public creatingNewDocType: boolean = false;
  public creatingNewParty: boolean = false;

  public user$: Observable<UserDto | null> | undefined;
  public currentUser: UserDto | null | undefined;
  public isDocumentLoading$ = this.store$.select(fromDocuments.selectDocumentLoading);
  public isDocumentEditing$ = this.store$.select(fromDocuments.selectDocumentEditing);
  public document: GetDocumentDto | null | undefined;
  public contentLanguages: LanguageDto[] = [];

  constructor(
    private store$: Store<AppState>,
    private bsModalRef: BsModalRef,
    private elementRef: ElementRef,
    private readonly fb: FormBuilder,
    private readonly cdr: ChangeDetectorRef,
    private readonly documentTypesApiService: DocumentTypesApiService,
    private readonly documentStatusesApiService: DocumentStatusesApiService,
    private readonly positionsApiService: PositionsApiService,
    private readonly partiesApiService: PartiesApiService,
    private readonly translocoService: TranslocoService,
  ) {}

  ngAfterContentChecked(): void {}

  ngAfterViewInit(): void {
    this.store$
      .select(fromDocuments.selectCloseEditingDocumentModal)
      .pipe(untilDestroyed(this))
      .subscribe((close) => {
        if (close) {
          this.bsModalRef.hide();
          this.store$.dispatch(DocumentsActions.clearEditDocumentCancelled());
        }
      });
  }

  public ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    combineLatest([
      this.store$.select(selectUser),
      this.store$.select(fromDocuments.selectDocument).pipe(filter(isPresent)),
      this.store$.select(selectContentLanguages).pipe(filter(isPresent)),
      this.documentTypesApiService.getDocumentTypes$(),
      this.documentStatusesApiService.getDocumentStatuses$(),
      this.positionsApiService.getPositions$(),
      this.partiesApiService.getParties$(),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([user, doc, contentLanguages, docTypes, docStatuses, postitions, parties]) => {
        this.currentUser = user;
        this.document = doc;
        this.contentLanguages = contentLanguages;
        this.docTypeItems = docTypes;
        this.docStatusItems = docStatuses;
        this.positionItems = postitions;
        this.conterPartyItems = parties;
        this.hiddenConterPartyItems = [];

        this.form = this.fb.group({
          documentName: [this.document?.name, { validators: [Validators.required] }],
          documentType: [this.document?.type, { validators: [Validators.required] }],
          counterparties: ['', { validators: [Validators.required] }],
          myPosition: [doc.userPositionId, { validators: [Validators.required] }],
          status: [this.document?.status, { validators: [Validators.required] }],
          languageId: [this.document?.languageId, { validators: [Validators.required] }],
        });

        this.addDocTypeForm = this.fb.group({
          newDocumentType: ['', { validators: [Validators.required] }],
        });

        this.addPartyForm = this.fb.group({
          newPartyName: ['', { validators: [Validators.required] }],
        });

        setTimeout(() => {
          this.form?.get('counterparties')?.patchValue(this.document?.parties);
        });

        this.cdr.markForCheck();
      });
  }

  openNewDocTypeForm() {
    this.creatingNewDocType = true;
  }

  onConfirmNewDocType() {
    this.documentTypesApiService
      .createDocumentType$(this.addDocTypeForm?.get('newDocumentType')?.value)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.addDocTypeForm?.get('newDocumentType')?.setErrors({ docTypeNameAlreadyExist: true });
          const invalidControl = this.elementRef.nativeElement.querySelector('#newDocumentType');
          invalidControl?.focus();
          return of();
        }),
      )
      .subscribe((resp: GetDocumentTypeDto) => {
        if (resp) {
          this.documentTypesApiService
            .getDocumentTypes$()
            .pipe(untilDestroyed(this))
            .subscribe((docTypeItems) => {
              this.docTypeItems = docTypeItems;
              this.cdr.detectChanges();

              setTimeout(() => {
                this.form?.get('documentType')?.patchValue(resp.id);
                this.form?.updateValueAndValidity();
                this.onCancelNewDocType();
                this.cdr.detectChanges();
              });
            });
        } else {
          this.onCancelNewDocType();
        }
      });
  }

  onCancelNewDocType() {
    this.addDocTypeForm?.reset();
    this.creatingNewDocType = false;
    this.cdr.markForCheck();
  }

  openNewPartyForm() {
    this.creatingNewParty = true;
  }

  onConfirmNewParty() {
    this.partiesApiService
      .createParty$(this.addPartyForm?.get('newPartyName')?.value)
      .pipe(
        untilDestroyed(this),
        catchError(() => {
          this.addPartyForm?.get('newPartyName')?.setErrors({ partyNameAlreadyExist: true });
          const invalidControl = this.elementRef.nativeElement.querySelector('#newPartyName');
          invalidControl?.focus();
          return of();
        }),
      )
      .subscribe((resp: PartyDto) => {
        if (resp) {
          this.store$.dispatch(CounterpartiesActions.getCounterparties());

          this.partiesApiService
            .getParties$()
            .pipe(untilDestroyed(this))
            .subscribe((parties) => {
              this.conterPartyItems = parties;
              this.cdr.detectChanges();

              setTimeout(() => {
                this.form?.get('counterparties')?.patchValue([resp.id]);
                this.form?.updateValueAndValidity();
                this.onCancelNewParty();
                this.creatingNewParty = false;
                this.cdr.detectChanges();
              });
            });
        } else {
          this.creatingNewParty = false;
        }
      });
  }

  onCancelNewParty() {
    this.addPartyForm?.reset();
    this.creatingNewParty = false;
    this.cdr.markForCheck();
  }

  public onConfirm(): void {
    const myPosition = this.form?.get('myPosition')?.value;
    const counterparties = this.form
      ?.get('counterparties')
      ?.value?.split(',')
      .map((id: string) => +id);

    const properties = {
      name: this.form?.get('documentName')?.value,
      type: this.form?.get('documentType')?.value,
      userPositionId: myPosition,
      parties: counterparties,
      status: this.form?.get('status')?.value,
      languageId: this.form?.get('languageId')?.value,
    } as DocumentPropertiesDto;

    const model = {
      properties,
    };

    this.store$.dispatch(DocumentsActions.editDocument({ model, uniqueId: this.uniqueId }));
  }

  public onCancel(): void {
    const pristine = !!this.form?.pristine;
    this.store$.dispatch(DocumentsActions.editDocumentCancel({ pristine }));
  }
}
