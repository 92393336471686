<div>
  @if (form) {
    <div [formGroup]="form">
      <div class="row-adaptive">
        <div class="col-adaptive">
          <app-search-input
            placeholder="{{ 'placeholders.filterByDocName' | transloco }}"
            formControlName="documentName"
            class="filter-control"
          ></app-search-input>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="documentType"
            [autocompleteOptions]="docTypeItems"
            [placeholderKey]="'placeholders.filterByTypeOfDoc'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="counterparty"
            [autocompleteOptions]="partyItems"
            [placeholderKey]="'placeholders.filterByCounterparty'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="position"
            [autocompleteOptions]="positionItems"
            [placeholderKey]="'placeholders.filterByMyPosition'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="status"
            [autocompleteOptions]="docStatusItems"
            [placeholderKey]="'placeholders.filterByStatus'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive">
          <app-tui-multi-select-autocomplete
            formControlName="languageId"
            [autocompleteOptions]="contentLanguages"
            optionLabel="displayName"
            [placeholderKey]="'placeholders.filterByLanguage'"
          ></app-tui-multi-select-autocomplete>
        </div>
        <div class="col-adaptive filter-btn-col">
          <app-button
            automation-id="create-btn"
            icon="filter_list_off"
            [type]="btnTypes.Icon_Stroked"
            [size]="btnSizes.InFilterRow"
            [title]="'buttons.clearFilters' | transloco"
            (onClick)="clearFilters()"
            class="w100"
          ></app-button>
        </div>
        <div class="col-adaptive plus-btn-col">
          <app-button
            automation-id="create-btn"
            class="w100"
            icon="add"
            [type]="btnTypes.Icon"
            [size]="btnSizes.InFilterRow"
            [title]="'buttons.addDocument' | transloco"
            (onClick)="addDocument()"
          ></app-button>
        </div>
      </div>
    </div>
  }

  @if (docsRecieved) {
    <app-documents-list class="app-documents-table"></app-documents-list>
  }
</div>
